<template>
  <Portlet
    title="Start Finish"
    icon="play"
    class="startFinish"
    portlet-body-class="p-0"
  >
    <template slot="buttons">
      <font-awesome-icon
        :class="['alt-pointer color-primary', { 'fa-spin' : loading}]"
        icon="sync-alt"
        style="vertical-align: middle;"
        @click="createInterval()"
      />
      <div class="btn-group-sm btn-group ml-2">
        <router-link
          :to="detailUrl"
          class="btn btn-sm btn-secondary"
        >
          <font-awesome-icon
            class="gray mr-1"
            icon="info-circle"
          />
          <span>
            {{ $t('details') }}
          </span>
        </router-link>
        <button
          v-if="authenticationHasRole('admin') || authenticationHasRole('secondlevel')"
          class="btn btn-secondary btn-sm"
          @click="showSidebar"
        >
          <font-awesome-icon
            class="gray mr-2"
            icon="edit"      
          />
          <span>{{ $t('changeMode') }}</span>
        </button>
        <router-link
          v-if="authenticationHasRole('admin') || authenticationHasRole('secondlevel')"
          :to="configUrl"
          class="btn btn-sm btn-secondary"
        >
          <font-awesome-icon
            class="gray mr-2"
            icon="wrench"    
          />
          <span>{{ $t('configuration') }}</span>
        </router-link>
        <button
          class="btn btn-sm btn-secondary"
          @click="openConfirmModal()"
        >
          <font-awesome-icon
            class="gray mr-2"
            icon="redo-alt"
          /><span>Restart</span>
        </button>
      </div>
    </template>

    <div
      v-if="currentState"
      class="p-3"
    >
      <StartFinishButtons
        v-if="!showSkiSchoolModeColor"
        :allowed-states="allowedStates"
        class="float-right"
        @identification="identification()"
        @start="start()"
        @finish="finish()"
        @abort="abort()"
      />
      <a
        :class="`badge badge-pill ${ operationModeClass } float-left ${ stateDisplayClass } p-3`"
        disabled
      >
        <span>{{ stateDisplayText }}</span>
        <br>
        <small v-if="!showSkiSchoolModeColor">{{ operationMode }}</small>
      </a>
      <div class="clearfix" />
    </div>
    <p
      v-else
      class="p-3"
    >
      {{ $t('noDataAvailable') }}
    </p>
    
    <div class="minusMargin">
      <table
        v-if="displayStatus"
        class="defaultTable"
      >
        <tbody>
          <tr>
            <td>
              <span class="badge badge-pill badge-info">{{ displayStatus.startDisplay }}</span>
            </td>
            <td>
              <span class="badge badge-pill badge-info">{{ displayStatus.finishDisplay }}</span>
            </td>
          </tr>
          <tr>
            <th class="py-1 px-2 text-lighter text-10">
              {{ $t('startDisplay') }}
            </th>
            <th class="py-1 px-2 text-lighter text-10">
              {{ $t('finishDisplay') }}
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <SweetModal
      ref="confirmRestart"
      title="Restart?"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('sureToRestart', {'0' : 'StartFinish'}) }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmRestart.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-primary float-right mb-3"
        @click="restartStartFinish()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="redo-alt"
        />{{ 'Restart' }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </Portlet>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue';
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: 'StartFinish',
  components: {
    StartFinishButtons: () => import('@/components/StartFinish/StartFinishButtons.vue'),
    SweetModal
  },
  mixins: [
    authenticationMixin,
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    uuid: {
      type: String,
      required: false,
      default: null
    },
    skiSchoolMode: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      loading: true,
      currentState: null,
      currentStateInterval: null,
      allowedStates: null,
      cancelSource: null,
      raceDuration: null,
      operationMode: null,
      orderCancelSourceToken: null,
      operationModeCancelSourceToken: null,
      intervalCounter: 0,
      displayStatus: null,
      skiSchoolModeInterval: null,
      showSkiSchoolModeColor: false
    }
  },
  computed: {
    stateDisplayClass: function () {
      let tmpState = this.currentState.toLowerCase();
      if(this.showSkiSchoolModeColor) {
        return 'badge-dark alt-no-pointer';
      }

      switch(tmpState) {
        case('active'):
          return 'badge-primary alt-no-pointer';
        case('waitforidentification'):
          return 'badge-info alt-no-pointer';
        case('waitforstart'):
          return 'badge-success alt-no-pointer';
        case('racing'):
          return 'badge-primary alt-no-pointer';
        default:
          return 'badge-primary alt-no-pointer';
      }
    },
    operationModeClass: function () {
      let defaultClass = '';
      if(this.operationMode && !this.showSkiSchoolModeColor)
      {
        let mode = this.operationMode.toLowerCase();
        if(mode === 'timingmode' || mode === 'failurefallbacktimingmode' )
        {
          return 'badge-warning'
        }
        return defaultClass;
      }
      return defaultClass;
    },
    stateDisplayText: function () {
      if(this.showSkiSchoolModeColor) {
        return 'Skischool Mode';
      }

      let tmpState = this.currentState.toLowerCase();
      switch(tmpState) {
        case('active'):
          return 'active';
        case('waitforidentification'):
          return 'Waiting for identification';
        case('waitforrace'):
          return 'Waiting for race';
        case('waitforstart'):
          return 'Waiting for start';
        case('racing'):
          return `Racing: ${ this.raceDuration }`;
        default:
          return 'Unknown';
      }
    },
    configUrl: function () {
      if (!this.laneNumber) //its a Dualslalom installation with own routing
      {
        return { path: `/installation/${ this.installationId }/tracker/config/startfinish` };
      }
      else
      {
        return { path: `/installation/${ this.installationId }/tracker/config/startfinish/lane/${ this.laneNumber }` };
      }
    },
    detailUrl: function () {
      if (!this.laneNumber)
      {
        return { path: `/installation/${ this.installationId }/tracker/startfinish` };
      }
      else
      {
        return { path: `/installation/${ this.installationId }/tracker/startfinish/lane/${ this.laneNumber }` };
      }
    }
  },
  watch: {
    intervalCounter () {
      if (this.intervalCounter > 5) {
        this.cancelInterval();
        this.cancelRequest();
        this.intervalCounter = 0;
      }
    }
  },
  created () {
    this.createInterval();
    this.getDisplayStatus();
    if(this.skiSchoolMode) {
      this.showSkiSchoolModeColor = this.skiSchoolMode;
      this.createSkiSchoolModeInterval();
    }
  },
  beforeDestroy () {
    this.cancelInterval();
    this.cancelRequest();
    this.cancelOrderRequest()
    this.cancelDisplayStatusRequest();
    this.cancelOperationModeRequest();
    if(this.skiSchoolMode) {
      this.cancelSkiSchoolModeInterval();
    }
  },
  methods: {
    openConfirmModal () {
      this.$refs.confirmRestart.open();
    },
    showSidebar () {
      this.$emit('showSidebar', this.operationMode, this.laneNumber);
    },
    cancelDisplayStatusRequest () {
      if (this.displayStatusSourceToken) {
        this.displayStatusSourceToken.cancel();
      }
    },
    getDisplayStatus () {
      this.cancelDisplayStatusRequest();
      this.displayStatusSourceToken = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.displayStatusSourceToken.token };
      let url = `/StartFinish/DisplayStatus?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }

          this.displayStatus = response.data;
        });
    },
    cancelOrderRequest () {
      if (this.orderCancelSourceToken) {
        this.orderCancelSourceToken.cancel();
      }
    },
    cancelInterval () {
      window.clearInterval(this.currentStateInterval);
      this.currentStateInterval = null;
      this.loading = false;
    },
    createInterval () {
      this.currentStateInterval = window.setInterval(() => {
        this.getCurrentState();
        this.getOperationMode();
        this.getDisplayStatus();
      }, 1000);
    },
    cancelSkiSchoolModeInterval () {
      window.clearInterval(this.skiSchoolModeInterval);
      this.skiSchoolModeInterval = null;
      this.loading = false;
    },
    createSkiSchoolModeInterval () {
      this.skiSchoolModeInterval = window.setInterval(() => {
        this.parseSkiSchoolConfig()
      }, 2000);
    },
    cancelRequest () {
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }
    },
    cancelOperationModeRequest () {
      if (this.operationModeCancelSourceToken) {
        this.operationModeCancelSourceToken.cancel();
      }
    },
    getOperationMode () {
      this.cancelOperationModeRequest();
      this.operationModeCancelSourceToken = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.operationModeCancelSourceToken.token };
      let url = `/StartFinish/GetOperationMode?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url, requestConfig)
      .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }

          this.operationMode = response.data.replace(/"/g, '');
      })
      .finally(() => {
        this.operationModeCancelSourceToken = null;
      });
    },
    getCurrentState () {
      this.loading = true;
      this.cancelRequest();
      this.cancelSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelSource.token };
      let url = `/StartFinish/CurrentState?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url, requestConfig)
        .then((response) => {
          if (response == null) {
            //this.intervalCounter++;
            return;
          }
          if (response.status == null) {
            //this.intervalCounter++;
            return;
          }
          if (response.status != 200) {
            return;
          }
          this.cancelSource = null;
          this.currentState = response.data.sessionState;
          if (response.data.allowedStates != null) {
            this.allowedStates = response.data.allowedStates;
          }
          this.raceDuration = response.data.raceDuration;
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
          this.cancelInterval();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // START forces the installation to pass the start-event
    start () {
      this.cancelOrderRequest();
      this.orderCancelSourceToken = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.orderCancelSourceToken.token };
      let url = `/StartFinish/Start?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url, requestConfig)
        .finally(() => {
          this.getCurrentState();
        })
    },
    // ABORT forces the installation to abort
    abort () {
      this.cancelOrderRequest();
      this.orderCancelSourceToken = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.orderCancelSourceToken.token };
      let url = `/StartFinish/Abort?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url, requestConfig)
        .finally(() => {
          this.getCurrentState();
          this.orderCancelSourceToken = null;
        })
    },
    // IDENTIFICATION forces the installation to pass the identified-event
    identification () {
      this.cancelOrderRequest();
      this.orderCancelSourceToken = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.orderCancelSourceToken.token };
      let url = `StartFinish/Identification?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url, requestConfig)
        .finally(() => {
          this.getCurrentState();
          this.orderCancelSourceToken = null;
        })
    },
    // FINISH forces the installation to finish the current race
    finish () {
      this.cancelOrderRequest();
      this.orderCancelSourceToken = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.orderCancelSourceToken.token };
      let url = `/StartFinish/Finish?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url, requestConfig)
        .finally(() => {
          this.getCurrentState();
          this.orderCancelSourceToken = null;
        })
    },
    restartStartFinish () {
      this.$refs.confirmRestart.close();
      this.axios.get(`/Endpoint/GetTrackerDevice?installationId=${this.installationId}&componentType=StartFinish&laneNumber=${this.laneNumber}`)
        .then((response) => {
          if (response) {
            let commandRequest = {
              'Command': 'restartStartFinish',
              'Args': ''
            };
            if (response.data && response.data.uuid)
            {
              this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ response.data.uuid }`, commandRequest)
                .then((response) => {
                  this.$snotify.info(response.data.output);
                })
                .catch(() => {
                   this.$snotify.error("Can not restart StartFinish");
                });
            }
        }
        });
    },
    parseSkiSchoolConfig () {
      this.axios.get(`/CentralDeviceManagement/HasDeviceSkischoolMode?deviceUuid=${this.uuid}`)
        .then((response) => {
          if (response) {
            this.showSkiSchoolModeColor = response.data;
          }
        });
    }
  }
}
</script>

<style scoped>
.badge.badge-pill.alt-no-pointer {
  font-size: 1rem;
  color: white;
  padding: 0.5rem;
}
.minusMargin {
  margin-right: -1px;
}
</style>

<style>
.startFinish .m-portlet__body {
  min-height: 78px !important;
}
</style>
